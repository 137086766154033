<template>
  <div class="tax-services-container"
    ><app-header rootClassName="header-root-class-name3"></app-header
    ><div class="tax-services-hero"
      ><div class="heroContainer tax-services-hero1"
        ><div class="tax-services-container01"
          ><h1 class="tax-services-hero-heading heading1"
            >Tailor made packages for your needs</h1
          ></div
        ></div
      ></div
    ><div class="tax-services-pricing"
      ><div class="tax-services-pricing1"
        ><div class="pricingContainer"
          ><div class="tax-services-container02"
            ><div class="tax-services-container03"
              ><h2 class="tax-services-pricing-heading heading3"
                >Choose the Right Plan for Your Tax Needs</h2
              ></div
            ><div class="tax-services-container04"
              ><div class="tax-services-pricing-card freePricingCard"
                ><div class="tax-services-container05"
                  ><span class="tax-services-text heading3"
                    >Tax payer registration</span
                  ></div
                ><div class="tax-services-container06"
                  ><span class="tax-services-text001">LKR</span
                  ><span class="tax-services-free-plan-price">17,000</span></div
                ><div class="tax-services-feature-container"
                  ><div class="tax-services-feature"
                    ><h1 class="tax-services-text002">Account reconciliation</h1
                    ><div class="tax-services-sub-feature"
                      ><span class="tax-services-text003">✔</span
                      ><span>Obtaining your (IRD) PIN</span></div
                    ><div class="tax-services-sub-feature01"
                      ><span class="tax-services-text004">✔</span
                      ><span
                        >Tax Type Registration and advice
                        <span v-html="rawkq49"></span></span></div
                    ><div class="tax-services-sub-feature02"
                      ><span class="tax-services-text005">✔</span
                      ><span
                        >Registration Confirmation follow up
                        <span v-html="raw32sf"></span></span></div
                    ><div class="tax-services-sub-feature03"
                      ><span class="tax-services-text006">✔</span
                      ><span
                        ><span>Record Keeping</span
                        ><br /><br /></span></div></div></div
                ><button class="tax-services-button buttonOutline"
                  >Continue with Free</button
                ></div
              ><div class="tax-services-pricing-card1 freePricingCard"
                ><div class="tax-services-container07"
                  ><span class="tax-services-text010 heading3"
                    >Tax payer registration</span
                  ></div
                ><div class="tax-services-container08"
                  ><span class="tax-services-text011">LKR</span
                  ><span class="tax-services-free-plan-price1">25,400</span></div
                ><div class="tax-services-feature-container1"
                  ><div class="tax-services-feature1"
                    ><h1 class="tax-services-text012">VAT registration</h1
                    ><div class="tax-services-sub-feature04"
                      ><span class="tax-services-text013">✔</span
                      ><span>Document preparation</span></div
                    ><div class="tax-services-sub-feature05"
                      ><span class="tax-services-text014">✔</span
                      ><span
                        >Coordination between IRD and the taxpayer
                        <span v-html="rawrscr"></span></span></div
                    ><div class="tax-services-sub-feature06"
                      ><span class="tax-services-text015">✔</span
                      ><span
                        ><span>Advice on VAT Registration</span><br /><span>
                          <span v-html="rawelyc"></span></span></span></div
                    ><div class="tax-services-sub-feature07"
                      ><span class="tax-services-text019">✔</span
                      ><span
                        ><span>VAT Registration</span
                        ><br /><br /></span></div></div></div
                ><button class="tax-services-button1 buttonOutline"
                  >Continue with Free</button
                ></div
              ></div
            ></div
          ><div class="tax-services-container09"
            ><div class="tax-services-container10"
              ><h2 class="tax-services-pricing-heading1 heading3"
                >Individual Income Tax (IIT) consultation packages</h2
              ></div
            ><div class="tax-services-container11"
              ><div class="tax-services-pricing-card2 freePricingCard"
                ><div class="tax-services-container12"
                  ><span class="tax-services-text023"
                    >Employment Income or Investment income only</span
                  ></div
                ><div class="tax-services-container13"
                  ><span class="tax-services-text024">LKR</span
                  ><span class="tax-services-free-plan-price2">17,000</span></div
                ><div class="tax-services-feature-container2"
                  ><div class="tax-services-feature2"
                    ><h1 class="tax-services-text025"
                      >Discussion of Personal tax matters with a CERTIFIED TAX
                      ADVISOR</h1
                    ><div class="tax-services-sub-feature08"
                      ><span class="tax-services-text026">✔</span
                      ><span>Personal tax Preparation Services</span></div
                    ><div class="tax-services-sub-feature09"
                      ><span class="tax-services-text027">✔</span
                      ><span class="tax-services-text028">✔</span
                      ><span
                        ><span>Tax registration</span><br /><span>
                          <span v-html="rawgbv1"></span></span></span></div
                    ><div class="tax-services-sub-feature10"
                      ><span class="tax-services-text032">✔</span
                      ><span
                        ><span
                          >Tax advisory service
                          <span v-html="rawvtd3"></span></span
                        ><br /><span>
                          <span v-html="raw2qw8"></span></span></span></div
                    ><div class="tax-services-sub-feature11"
                      ><span class="tax-services-text036">✔</span
                      ><span
                        ><span>Quarterly tax payments advices</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature12"
                      ><span class="tax-services-text039">✔</span
                      ><span
                        ><span>Tax Reminders on Tax due dates &amp; Payment</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature13"
                      ><span class="tax-services-text042">✔</span
                      ><span
                        ><span
                          >Preparation &amp; Filing of Final Tax Return
                          online</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature14"
                      ><span class="tax-services-text045">✔</span
                      ><span>Post Return filing support</span></div
                    ></div
                  ></div
                ><button class="tax-services-button2 buttonOutline"
                  >Continue with Free</button
                ></div
              ><div class="tax-services-pricing-card3 freePricingCard"
                ><div class="tax-services-container14"
                  ><span class="tax-services-text046"
                    >Employment Income &amp; Investment income only</span
                  ></div
                ><div class="tax-services-container15"
                  ><span class="tax-services-text047">LKR</span
                  ><span class="tax-services-free-plan-price3">21,200</span></div
                ><div class="tax-services-feature-container3"
                  ><div class="tax-services-feature3"
                    ><h1 class="tax-services-text048"
                      >Discussion of Personal tax matters with a CERTIFIED TAX
                      ADVISOR</h1
                    ><div class="tax-services-sub-feature15"
                      ><span class="tax-services-text049">✔</span
                      ><span>Personal tax Preparation Services</span></div
                    ><div class="tax-services-sub-feature16"
                      ><span class="tax-services-text050">✔</span
                      ><span
                        ><span>Tax registration</span><br /><span>
                          <span v-html="rawes0w"></span></span></span></div
                    ><div class="tax-services-sub-feature17"
                      ><span class="tax-services-text054">✔</span
                      ><span
                        ><span>Quarterly tax payments advices</span><br /><span>
                          <span v-html="raw3vo1"></span></span></span></div
                    ><div class="tax-services-sub-feature18"
                      ><span class="tax-services-text058">✔</span
                      ><span
                        ><span>Tax Reminders on Tax due dates &amp; Payment</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature19"
                      ><span class="tax-services-text061">✔</span
                      ><span
                        ><span
                          >Advising &amp; filing of Statement of Estimated Tax
                          (SET)</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature20"
                      ><span class="tax-services-text064">✔</span
                      ><span
                        ><span
                          >Preparation &amp; Filing of Final Tax Return
                          online</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature21"
                      ><span class="tax-services-text067">✔</span
                      ><span
                        ><span>Post Return filing support</span><br /></span></div
                    ><div class="tax-services-sub-feature22"
                      ><span class="tax-services-text070">✔</span
                      ><span
                        ><span
                          >For timely &amp; effective tax completion, it is
                          advisable to submit your requirements in advance</span
                        ><br /></span></div></div></div
                ><button class="tax-services-button3 buttonOutline"
                  >Continue with Free</button
                ></div
              ><div class="tax-services-pricing-card4 freePricingCard"
                ><div class="tax-services-container16"
                  ><span class="tax-services-text073"
                    >Investment Income / Employment Income/Business Income
                    (without Financial statements)</span
                  ></div
                ><div class="tax-services-container17"
                  ><span class="tax-services-text074">LKR</span
                  ><span class="tax-services-free-plan-price4">25,400</span></div
                ><div class="tax-services-feature-container4"
                  ><div class="tax-services-feature4"
                    ><h1 class="tax-services-text075"
                      >Discussion of Personal tax matters with a CERTIFIED TAX
                      ADVISOR</h1
                    ><div class="tax-services-sub-feature23"
                      ><span class="tax-services-text076">✔</span
                      ><span>Personal tax Preparation Services</span></div
                    ><div class="tax-services-sub-feature24"
                      ><span class="tax-services-text077">✔</span
                      ><span
                        >Tax registration
                        <span v-html="rawz2uv"></span></span></div
                    ><div class="tax-services-sub-feature25"
                      ><span class="tax-services-text078">✔</span
                      ><span
                        ><span
                          >Tax advisory service
                          <span v-html="raw0653"></span></span
                        ><br /><span>
                          <span v-html="rawxaaf"></span></span></span></div
                    ><div class="tax-services-sub-feature26"
                      ><span class="tax-services-text082">✔</span
                      ><span
                        ><span>Quarterly tax payments advices</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature27"
                      ><span class="tax-services-text085">✔</span
                      ><span
                        ><span>Tax Reminders on Tax due dates &amp; Payment</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature28"
                      ><span class="tax-services-text088">✔</span
                      ><span
                        ><span
                          >Advising &amp; filing of Statement of Estimated Tax
                          (SET)</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature29"
                      ><span class="tax-services-text091">✔</span
                      ><span
                        ><span
                          >Preparation &amp; Filing of Final Tax Return
                          online</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature30"
                      ><span class="tax-services-text094">✔</span
                      ><span
                        ><span>Post Return filing support</span
                        ><br /></span></div></div></div
                ><button class="tax-services-button4 buttonOutline"
                  >Continue with Free</button
                ></div
              ><div class="tax-services-pricing-card5 freePricingCard"
                ><div class="tax-services-container18"
                  ><span class="tax-services-text097"
                    >Investment Income / Employment Income/Business Income with
                    Financial statements)</span
                  ></div
                ><div class="tax-services-container19"
                  ><span class="tax-services-text098">LKR</span
                  ><span class="tax-services-free-plan-price5">33,900</span></div
                ><div class="tax-services-feature-container5"
                  ><div class="tax-services-feature5"
                    ><h1 class="tax-services-text099"
                      >Discussion of Personal tax matters with a CERTIFIED TAX
                      ADVISOR</h1
                    ><div class="tax-services-sub-feature31"
                      ><span class="tax-services-text100">✔</span
                      ><span>Personal tax Preparation Services</span></div
                    ><div class="tax-services-sub-feature32"
                      ><span class="tax-services-text101">✔</span
                      ><span
                        >Tax registration
                        <span v-html="rawjkzw"></span></span></div
                    ><div class="tax-services-sub-feature33"
                      ><span class="tax-services-text102">✔</span
                      ><span
                        ><span>Tax advisory service</span><br /><span>
                          <span v-html="rawnx3s"></span></span></span></div
                    ><div class="tax-services-sub-feature34"
                      ><span class="tax-services-text106">✔</span
                      ><span
                        ><span>Quarterly tax payments advices</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature35"
                      ><span class="tax-services-text109">✔</span
                      ><span
                        ><span>Tax Reminders on Tax due dates &amp; Payment</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature36"
                      ><span class="tax-services-text112">✔</span
                      ><span
                        ><span
                          >Advising &amp; filing of Statement of Estimated Tax
                          (SET)</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature37"
                      ><span class="tax-services-text115">✔</span
                      ><span
                        ><span
                          >Preparation &amp; Filing of Final Tax Return
                          online</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature38"
                      ><span class="tax-services-text118">✔</span
                      ><span
                        ><span>Post Return filing support</span
                        ><br /></span></div></div></div
                ><button class="tax-services-button5 buttonOutline"
                  >Continue with Free</button
                ></div
              ></div
            ></div
          ><div class="tax-services-container20"
            ><div class="tax-services-container21"
              ><h2 class="tax-services-pricing-heading2 heading3"
                >Companies/Charitable  Institutions/NGOs &amp; Other
                Organizations</h2
              ></div
            ><div class="tax-services-container22"
              ><div class="tax-services-pricing-card6 freePricingCard"
                ><div class="tax-services-container23"
                  ><span class="tax-services-text121"
                    >Investment Income / /Business Income (with/OR without
                    Financial statements)</span
                  ></div
                ><div class="tax-services-container24"
                  ><span class="tax-services-free-plan-price6"
                    >Fee on A quotation Basis</span
                  ></div
                ><div class="tax-services-feature-container6"
                  ><div class="tax-services-feature6"
                    ><h1 class="tax-services-text122"
                      >Discussion of Personal tax matters with a CERTIFIED TAX
                      ADVISOR</h1
                    ><div class="tax-services-sub-feature39"
                      ><span class="tax-services-text123">✔</span
                      ><span>Personal tax Preparation Services</span></div
                    ><div class="tax-services-sub-feature40"
                      ><span class="tax-services-text124">✔</span
                      ><span class="tax-services-text125">✔</span
                      ><span
                        ><span>Tax registration</span><br /><span>
                          <span v-html="rawkzyo"></span></span></span></div
                    ><div class="tax-services-sub-feature41"
                      ><span class="tax-services-text129">✔</span
                      ><span
                        ><span
                          >Tax advisory service
                          <span v-html="rawd9mu"></span></span
                        ><br /><span>
                          <span v-html="rawpgtl"></span></span></span></div
                    ><div class="tax-services-sub-feature42"
                      ><span class="tax-services-text133">✔</span
                      ><span
                        ><span>Quarterly tax payments advices</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature43"
                      ><span class="tax-services-text136">✔</span
                      ><span
                        ><span>Tax Reminders on Tax due dates &amp; Payment</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature44"
                      ><span class="tax-services-text139">✔</span
                      ><span
                        ><span
                          >Preparation &amp; Filing of Final Tax Return
                          online</span
                        ><br /></span></div
                    ><div class="tax-services-sub-feature45"
                      ><span class="tax-services-text142">✔</span
                      ><span>Post Return filing support</span></div
                    ></div
                  ></div
                ><a
                  href="mailto:info@taxonus.lk?subject=Custom Accounting Quote"
                  class="tax-services-link buttonOutline"
                  >Request a quote</a
                ></div
              ></div
            ></div
          ><p
            >For timely &amp; effective tax completion, it is advisable to submit
            your requirements in advance.</p
          ></div
        ></div
      ></div
    ><div class="tax-services-download-center"
      ><div class="faqContainer"
        ><div class="tax-services-download"
          ><div class="tax-services-container25"
            ><h2 class="tax-services-text144 heading2">Download Center</h2></div
          ><div class="tax-services-container26"
            ><h3><span>Download link 1</span><br /></h3
            ><a
              href="https://www.google.lk"
              target="_blank"
              rel="noreferrer noopener"
              class="tax-services-link1"
              ><span>Download here</span><br /></a></div></div></div></div
    ><div class="tax-services-footer"
      ><footer class="footerContainer tax-services-footer1"
        ><div class="tax-services-container27"
          ><span class="TaxOnUs">TAXPROS</span
          ><nav class="tax-services-nav"
            ><span class="bodySmall">Services</span
            ><span class="tax-services-nav22 bodySmall">About Us</span
            ><span class="tax-services-nav32 bodySmall">Resources</span
            ><span class="tax-services-nav42 bodySmall">Contact Us</span></nav
          ></div
        ><div class="tax-services-separator"></div
        ><div class="tax-services-container28"
          ><span class="bodySmall tax-services-text150"
            >© 2023 myCompany, All Rights Reserved.</span
          ><div class="tax-services-icon-group"
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="tax-services-icon socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="tax-services-icon2 socialIcons"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg
              viewBox="0 0 602.2582857142856 1024"
              class="tax-services-icon4 socialIcons"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path></svg></div></div></footer></div
  ></div>
</template>

<script>
import AppHeader from '../components/header'

export default {
  name: 'TaxServices',
  props: {},
  components: {
    AppHeader,
  },
  data() {
    return {
      rawkq49: ' ',
      raw32sf: ' ',
      rawrscr: ' ',
      rawelyc: ' ',
      rawgbv1: ' ',
      rawvtd3: ' ',
      raw2qw8: ' ',
      rawes0w: ' ',
      raw3vo1: ' ',
      rawz2uv: ' ',
      raw0653: ' ',
      rawxaaf: ' ',
      rawjkzw: ' ',
      rawnx3s: ' ',
      rawkzyo: ' ',
      rawd9mu: ' ',
      rawpgtl: ' ',
    }
  },
  metaInfo: {
    title: 'Tax-Services - taxonus',
    meta: [
      {
        property: 'og:title',
        content: 'Tax-Services - taxonus',
      },
    ],
  },
}
</script>

<style scoped>
.tax-services-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tax-services-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.tax-services-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-hero-heading {
  max-width: 800px;
  text-align: center;
}
.tax-services-pricing {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-services-pricing1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-services-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tax-services-container03 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-pricing-heading {
  text-transform: none;
  text-decoration: none;
}
.tax-services-container04 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tax-services-pricing-card {
  width: var(--dl-size-size-xxlarge);
}
.tax-services-container05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text {
  text-transform: uppercase;
}
.tax-services-container06 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text001 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price {
  font-size: 4rem;
}
.tax-services-feature-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text002 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text003 {
  line-height: 150%;
}
.tax-services-sub-feature01 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text004 {
  line-height: 150%;
}
.tax-services-sub-feature02 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text005 {
  line-height: 150%;
}
.tax-services-sub-feature03 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text006 {
  line-height: 150%;
}
.tax-services-button {
  width: 100%;
  display: none;
}
.tax-services-pricing-card1 {
  width: var(--dl-size-size-xxlarge);
}
.tax-services-container07 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text010 {
  text-transform: uppercase;
}
.tax-services-container08 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text011 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price1 {
  font-size: 4rem;
}
.tax-services-feature-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text012 {
  font-size: 1.5em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature04 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text013 {
  line-height: 150%;
}
.tax-services-sub-feature05 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text014 {
  line-height: 150%;
}
.tax-services-sub-feature06 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text015 {
  line-height: 150%;
}
.tax-services-sub-feature07 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text019 {
  line-height: 150%;
}
.tax-services-button1 {
  width: 100%;
  display: none;
}
.tax-services-container09 {
  width: 100%;
  display: flex;
  margin-top: 2%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tax-services-container10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-pricing-heading1 {
  text-transform: none;
  text-decoration: none;
}
.tax-services-container11 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tax-services-pricing-card2 {
  width: var(--dl-size-size-xxlarge);
}
.tax-services-container12 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text023 {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.tax-services-container13 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text024 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price2 {
  font-size: 4rem;
}
.tax-services-feature-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text025 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature08 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text026 {
  line-height: 150%;
}
.tax-services-sub-feature09 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text027 {
  line-height: 150%;
}
.tax-services-text028 {
  line-height: 150%;
}
.tax-services-sub-feature10 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text032 {
  line-height: 150%;
}
.tax-services-sub-feature11 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text036 {
  line-height: 150%;
}
.tax-services-sub-feature12 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text039 {
  line-height: 150%;
}
.tax-services-sub-feature13 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text042 {
  line-height: 150%;
}
.tax-services-sub-feature14 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text045 {
  line-height: 150%;
}
.tax-services-button2 {
  width: 100%;
  display: none;
}
.tax-services-pricing-card3 {
  width: var(--dl-size-size-xxlarge);
}
.tax-services-container14 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text046 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.tax-services-container15 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text047 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price3 {
  font-size: 4rem;
}
.tax-services-feature-container3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text048 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature15 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text049 {
  line-height: 150%;
}
.tax-services-sub-feature16 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text050 {
  line-height: 150%;
}
.tax-services-sub-feature17 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text054 {
  line-height: 150%;
}
.tax-services-sub-feature18 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text058 {
  line-height: 150%;
}
.tax-services-sub-feature19 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text061 {
  line-height: 150%;
}
.tax-services-sub-feature20 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text064 {
  line-height: 150%;
}
.tax-services-sub-feature21 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text067 {
  line-height: 150%;
}
.tax-services-sub-feature22 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text070 {
  line-height: 150%;
}
.tax-services-button3 {
  width: 100%;
  display: none;
}
.tax-services-pricing-card4 {
  width: var(--dl-size-size-xxlarge);
}
.tax-services-container16 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text073 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.tax-services-container17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text074 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price4 {
  font-size: 4rem;
}
.tax-services-feature-container4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text075 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature23 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text076 {
  line-height: 150%;
}
.tax-services-sub-feature24 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text077 {
  line-height: 150%;
}
.tax-services-sub-feature25 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text078 {
  line-height: 150%;
}
.tax-services-sub-feature26 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text082 {
  line-height: 150%;
}
.tax-services-sub-feature27 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text085 {
  line-height: 150%;
}
.tax-services-sub-feature28 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text088 {
  line-height: 150%;
}
.tax-services-sub-feature29 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text091 {
  line-height: 150%;
}
.tax-services-sub-feature30 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text094 {
  line-height: 150%;
}
.tax-services-button4 {
  width: 100%;
  display: none;
}
.tax-services-pricing-card5 {
  width: var(--dl-size-size-xxlarge);
}
.tax-services-container18 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text097 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.tax-services-container19 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-text098 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.tax-services-free-plan-price5 {
  font-size: 4rem;
}
.tax-services-feature-container5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text099 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature31 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text100 {
  line-height: 150%;
}
.tax-services-sub-feature32 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text101 {
  line-height: 150%;
}
.tax-services-sub-feature33 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text102 {
  line-height: 150%;
}
.tax-services-sub-feature34 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text106 {
  line-height: 150%;
}
.tax-services-sub-feature35 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text109 {
  line-height: 150%;
}
.tax-services-sub-feature36 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text112 {
  line-height: 150%;
}
.tax-services-sub-feature37 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text115 {
  line-height: 150%;
}
.tax-services-sub-feature38 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text118 {
  line-height: 150%;
}
.tax-services-button5 {
  width: 100%;
  display: none;
}
.tax-services-container20 {
  width: 100%;
  display: flex;
  margin-top: 2%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tax-services-container21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-pricing-heading2 {
  text-transform: none;
  text-decoration: none;
}
.tax-services-container22 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tax-services-pricing-card6 {
  width: var(--dl-size-size-xxlarge);
}
.tax-services-container23 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text121 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.tax-services-container24 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.tax-services-free-plan-price6 {
  font-size: 3rem;
}
.tax-services-feature-container6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-feature6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.tax-services-text122 {
  font-size: 1.25em;
  margin-bottom: var(--dl-space-space-halfunit);
}
.tax-services-sub-feature39 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text123 {
  line-height: 150%;
}
.tax-services-sub-feature40 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text124 {
  line-height: 150%;
}
.tax-services-text125 {
  line-height: 150%;
}
.tax-services-sub-feature41 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text129 {
  line-height: 150%;
}
.tax-services-sub-feature42 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text133 {
  line-height: 150%;
}
.tax-services-sub-feature43 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text136 {
  line-height: 150%;
}
.tax-services-sub-feature44 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text139 {
  line-height: 150%;
}
.tax-services-sub-feature45 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0px;
  flex-direction: row;
}
.tax-services-text142 {
  line-height: 150%;
}
.tax-services-link {
  width: 100%;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 500;
  text-decoration: none;
}
.tax-services-download-center {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-services-download {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.tax-services-container25 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-text144 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.tax-services-container26 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tax-services-link1 {
  text-decoration: none;
}
.tax-services-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-services-container27 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tax-services-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.tax-services-nav22 {
  margin-left: var(--dl-space-space-unit);
}
.tax-services-nav32 {
  margin-left: var(--dl-space-space-unit);
}
.tax-services-nav42 {
  margin-left: var(--dl-space-space-unit);
}
.tax-services-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.tax-services-container28 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.tax-services-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.tax-services-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.tax-services-icon2 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.tax-services-icon4 {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .tax-services-hero1 {
    flex-direction: column;
  }
  .tax-services-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .tax-services-hero-heading {
    text-align: center;
  }
  .tax-services-container04 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-pricing-card1 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-container11 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card2 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-pricing-card3 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-pricing-card4 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-pricing-card5 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .tax-services-container22 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card6 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
}
@media(max-width: 767px) {
  .tax-services-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .tax-services-container04 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-pricing-card1 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-container11 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card2 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-pricing-card3 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-pricing-card4 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-pricing-card5 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-container22 {
    align-items: center;
    flex-direction: column;
  }
  .tax-services-pricing-card6 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-download {
    flex-direction: column;
  }
  .tax-services-container25 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .tax-services-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .tax-services-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .tax-services-container28 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .tax-services-text150 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .tax-services-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .tax-services-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .tax-services-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .tax-services-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .tax-services-container28 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .tax-services-text150 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
