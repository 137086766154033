<template>
  <div class="contact-container"
    ><app-header rootClassName="header-root-class-name2"></app-header
    ><div class="contact-hero"
      ><div class="heroContainer contact-hero1"
        ><div class="contact-container1"
          ><h1 class="contact-hero-heading heading1">Maximize Your Tax Refund</h1
          ><span class="contact-hero-sub-heading bodyLarge"
            ><span
              ><span
                ><span>Your Trusted Tax Experts</span
                ><span> <span v-html="rawu074"></span></span></span
              ><span
                ><span> <span v-html="rawzxr7"></span></span
                ><span> <span v-html="rawyf4d"></span></span></span></span
            ><span
              ><span
                ><span> <span v-html="raw4l77"></span></span
                ><span> <span v-html="rawazly"></span></span></span
              ><span
                ><span> <span v-html="rawoipg"></span></span
                ><span> <span v-html="rawaatm"></span></span></span></span></span
          ><div class="contact-btn-group"
            ><button class="buttonFilled">Get Started Now</button
            ><button class="buttonFlat">Learn More →</button></div
          ></div
        ></div
      ></div
    ><header data-thq="thq-navbar" class="contact-navbar-interactive"
      ><img
        alt="logo"
        src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
        class="contact-image" /><div
        data-thq="thq-navbar-nav"
        class="contact-desktop-menu"
        ><nav class="contact-links"
          ><span>About</span><span class="contact-text15">Features</span
          ><span class="contact-text16">Pricing</span
          ><span class="contact-text17">Team</span
          ><span class="contact-text18">Blog</span></nav
        ><div class="contact-buttons"
          ><button class="contact-login button">Login</button
          ><button class="button">Register</button></div
        ></div
      ><div data-thq="thq-burger-menu" class="contact-burger-menu"
        ><svg viewBox="0 0 1024 1024" class="contact-icon">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
          ></path></svg></div
      ><div data-thq="thq-mobile-menu" class="contact-mobile-menu"
        ><div class="contact-nav"
          ><div class="contact-top"
            ><img
              alt="image"
              src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
              class="contact-logo" /><div
              data-thq="thq-close-menu"
              class="contact-close-menu"
              ><svg viewBox="0 0 1024 1024" class="contact-icon02">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path></svg></div></div
          ><nav class="contact-links1"
            ><span class="contact-text19">About</span
            ><span class="contact-text20">Features</span
            ><span class="contact-text21">Pricing</span
            ><span class="contact-text22">Team</span
            ><span class="contact-text23">Blog</span></nav
          ><div class="contact-buttons1"
            ><button class="contact-login1 button">Login</button
            ><button class="button">Register</button></div
          ></div
        ><div
          ><svg viewBox="0 0 950.8571428571428 1024" class="contact-icon04">
            <path
              d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
            ></path></svg
          ><svg viewBox="0 0 877.7142857142857 1024" class="contact-icon06">
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
            ></path></svg
          ><svg viewBox="0 0 602.2582857142856 1024" class="contact-icon08">
            <path
              d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
            ></path></svg></div></div></header
    ><div class="contact-container2"
      ><form class="contact-form"
        ><div class="contact-container3"
          ><div class="contact-container4"
            ><input
              type="text"
              placeholder="Name"
              required="true"
              class="contact-textinput input" /><input
              type="tel"
              placeholder="Tel"
              required="true"
              class="contact-textinput1 input" /><input
              type="email"
              placeholder="Email"
              required="true"
              class="contact-textinput2 input" /></div
          ><div class="contact-container5">
            <textarea
              placeholder="Message"
              class="textarea contact-textarea"
            ></textarea></div></div></form
      ><div class="contact-container6"
        ><button type="button" class="button">Button</button></div
      ></div
    ><div class="contact-footer"
      ><footer class="footerContainer contact-footer1"
        ><div class="contact-container7"
          ><span class="TaxOnUs">TAXPROS</span
          ><nav class="contact-nav1"
            ><span class="bodySmall">Services</span
            ><span class="contact-nav22 bodySmall">About Us</span
            ><span class="contact-nav32 bodySmall">Resources</span
            ><span class="contact-nav42 bodySmall">Contact Us</span></nav
          ></div
        ><div class="contact-separator"></div
        ><div class="contact-container8"
          ><span class="bodySmall contact-text24"
            >© 2023 myCompany, All Rights Reserved.</span
          ><div class="contact-icon-group1"
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="contact-icon10 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="contact-icon12 socialIcons"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg
              viewBox="0 0 602.2582857142856 1024"
              class="contact-icon14 socialIcons"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path></svg></div></div></footer></div
  ></div>
</template>

<script>
import AppHeader from '../components/header'

export default {
  name: 'Contact',
  props: {},
  components: {
    AppHeader,
  },
  data() {
    return {
      rawu074: ' ',
      rawzxr7: ' ',
      rawyf4d: ' ',
      raw4l77: ' ',
      rawazly: ' ',
      rawoipg: ' ',
      rawaatm: ' ',
    }
  },
  metaInfo: {
    title: 'Contact - taxonus',
    meta: [
      {
        property: 'og:title',
        content: 'Contact - taxonus',
      },
    ],
  },
}
</script>

<style scoped>
.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.contact-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-hero-heading {
  max-width: 800px;
  text-align: center;
}
.contact-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.contact-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.contact-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.contact-image {
  height: 2rem;
}
.contact-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.contact-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact-text15 {
  margin-left: var(--dl-space-space-twounits);
}
.contact-text16 {
  margin-left: var(--dl-space-space-twounits);
}
.contact-text17 {
  margin-left: var(--dl-space-space-twounits);
}
.contact-text18 {
  margin-left: var(--dl-space-space-twounits);
}
.contact-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-login {
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
}
.contact-burger-menu {
  display: none;
}
.contact-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.contact-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.contact-logo {
  height: 2rem;
}
.contact-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-text19 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text20 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text21 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text22 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text23 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-login1 {
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-container2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
  background-color: var(--dl-color-custom-accent1);
}
.contact-form {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-self: flex-start;
}
.contact-container3 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
}
.contact-container4 {
  width: 50%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.contact-textinput {
  height: var(--dl-size-size-small);
}
.contact-textinput1 {
  height: var(--dl-size-size-small);
}
.contact-textinput2 {
  height: var(--dl-size-size-small);
}
.contact-container5 {
  width: 50%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.contact-container6 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-container7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.contact-nav22 {
  margin-left: var(--dl-space-space-unit);
}
.contact-nav32 {
  margin-left: var(--dl-space-space-unit);
}
.contact-nav42 {
  margin-left: var(--dl-space-space-unit);
}
.contact-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.contact-container8 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon14 {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .contact-hero1 {
    flex-direction: column;
  }
  .contact-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .contact-hero-heading {
    text-align: center;
  }
  .contact-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .contact-textinput {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-textinput1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-textinput2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-textarea {
    width: 100%;
    height: 100%;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-container6 {
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 767px) {
  .contact-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .contact-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-desktop-menu {
    display: none;
  }
  .contact-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-text19 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-text20 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-text21 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-text22 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-text23 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .contact-container8 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-text24 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .contact-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .contact-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-btn-group {
    flex-direction: column;
  }
  .contact-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .contact-mobile-menu {
    padding: 16px;
  }
  .contact-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .contact-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .contact-container8 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-text24 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
